import React from "react"; 
import loader from './assets/neo_icons/Menu/neo_infinity_loading_animation.gif'
import Image from "components/Core/Image/ImageNDL";
import { useRecoilState } from "recoil"; 
function LoadingScreenNDL(props) { 

    return (
        <div>
            <React.Fragment>
                <div style={{ top: 0, left: props.open ?"40px" :"250px", right: 0, bottom: 0, display: "flex", zIndex: 20, position: "fixed", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(0 0 0 / 20%)" }}>
                    {/* <Image src={loader} alt="Loader" width={100} ></Image> */}
                    <div class="customloader"></div>
                </div>
            </React.Fragment>
        </div>
    );

}

export default LoadingScreenNDL;